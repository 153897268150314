let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


let WASM_VECTOR_LEN = 0;

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

let cachedFloat64Memory0 = null;

function getFloat64Memory0() {
    if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

const CLOSURE_DTORS = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(state => {
    wasm.__wbindgen_export_3.get(state.dtor)(state.a, state.b)
});

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_3.get(state.dtor)(a, state.b);
                CLOSURE_DTORS.unregister(state);
            } else {
                state.a = a;
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}
function __wbg_adapter_38(arg0, arg1, arg2) {
    wasm.closure324_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_41(arg0, arg1) {
    wasm.wasm_bindgen__convert__closures__invoke0_mut__h2934327c8a6a54cc(arg0, arg1);
}

function makeClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        try {
            return f(state.a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_3.get(state.dtor)(state.a, state.b);
                state.a = 0;
                CLOSURE_DTORS.unregister(state);
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}
function __wbg_adapter_46(arg0, arg1, arg2, arg3) {
    wasm.wasm_bindgen__convert__closures__invoke2__h3b9503cd9fa69a98(arg0, arg1, arg2, arg3);
}

function __wbg_adapter_49(arg0, arg1, arg2) {
    wasm.wasm_bindgen__convert__closures__invoke1__h49cf0605b13ffe6c(arg0, arg1, arg2);
}

function __wbg_adapter_52(arg0, arg1, arg2) {
    wasm.wasm_bindgen__convert__closures__invoke1__h49cf0605b13ffe6c(arg0, arg1, arg2);
}

function __wbg_adapter_55(arg0, arg1, arg2, arg3, arg4) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke3__hc5d6a41145bc24f0(arg0, arg1, arg2, arg3, arg4);
    return ret >>> 0;
}

function __wbg_adapter_58(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h8d19088ed6b276ba(arg0, arg1);
    return ret;
}

function __wbg_adapter_61(arg0, arg1, arg2, arg3) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke2__hb2348b32b7364d9f(arg0, arg1, arg2, arg3);
    return ret >>> 0;
}

function __wbg_adapter_64(arg0, arg1, arg2) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke1__hb3d0a1f2232cc5b6(arg0, arg1, arg2);
    return ret !== 0;
}

function takeFromExternrefTable0(idx) {
    const value = wasm.__wbindgen_export_2.get(idx);
    wasm.__externref_table_dealloc(idx);
    return value;
}
function __wbg_adapter_67(arg0, arg1, arg2, arg3, arg4, arg5) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.wasm_bindgen__convert__closures__invoke4__hec241b108fecaa40(retptr, arg0, arg1, arg2, arg3, arg4, arg5);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_70(arg0, arg1, arg2, arg3, arg4, arg5) {
    wasm.wasm_bindgen__convert__closures__invoke4__h6ef932bbe828df5f(arg0, arg1, arg2, arg3, arg4, arg5);
}

function __wbg_adapter_73(arg0, arg1, arg2) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke1__hf607f4f7f9eca542(arg0, arg1, arg2);
    return ret >>> 0;
}

function __wbg_adapter_76(arg0, arg1, arg2, arg3) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke2__hb2348b32b7364d9f(arg0, arg1, arg2, arg3);
    return ret;
}

function __wbg_adapter_79(arg0, arg1) {
    wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hd22b587941fdcbfb(arg0, arg1);
}

function __wbg_adapter_82(arg0, arg1, arg2, arg3) {
    wasm.wasm_bindgen__convert__closures__invoke2__hc5e435b0ad5cf7d6(arg0, arg1, arg2, arg3);
}

function __wbg_adapter_85(arg0, arg1, arg2) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.closure3047_externref_shim(retptr, arg0, arg1, arg2);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_88(arg0, arg1, arg2, arg3, arg4) {
    wasm.wasm_bindgen__convert__closures__invoke3__h751dc0cc4430f380(arg0, arg1, arg2, arg3, arg4);
}

function __wbg_adapter_91(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h9ec38816755b4d61(arg0, arg1);
    return ret !== 0;
}

function __wbg_adapter_94(arg0, arg1, arg2, arg3, arg4) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.wasm_bindgen__convert__closures__invoke3__h65c70699f1ab235d(retptr, arg0, arg1, arg2, arg3, arg4);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_97(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h5d6a7c45a445275e(arg0, arg1);
    return ret >>> 0;
}

function __wbg_adapter_100(arg0, arg1, arg2, arg3, arg4, arg5) {
    wasm.wasm_bindgen__convert__closures__invoke4__h6ef932bbe828df5f(arg0, arg1, arg2, arg3, arg4, arg5);
}

function __wbg_adapter_103(arg0, arg1, arg2) {
    wasm.closure3072_externref_shim(arg0, arg1, arg2);
}

/**
*/
export function main() {
    wasm.main();
}

function getFromExternrefTable0(idx) { return wasm.__wbindgen_export_2.get(idx); }

function getCachedStringFromWasm0(ptr, len) {
    if (ptr === 0) {
        return getFromExternrefTable0(len);
    } else {
        return getStringFromWasm0(ptr, len);
    }
}

function addToExternrefTable0(obj) {
    const idx = wasm.__externref_table_alloc();
    wasm.__wbindgen_export_2.set(idx, obj);
    return idx;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        const idx = addToExternrefTable0(e);
        wasm.__wbindgen_exn_store(idx);
    }
}

function notDefined(what) { return () => { throw new Error(`${what} is not defined`); }; }

function getArrayI32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getInt32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
    if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
        cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32Memory0;
}

function getArrayU32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedFloat32Memory0 = null;

function getFloat32Memory0() {
    if (cachedFloat32Memory0 === null || cachedFloat32Memory0.byteLength === 0) {
        cachedFloat32Memory0 = new Float32Array(wasm.memory.buffer);
    }
    return cachedFloat32Memory0;
}

function getArrayF32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getFloat32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

const EnvProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_envproxy_free(ptr >>> 0));
/**
*/
export class EnvProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EnvProxy.prototype);
        obj.__wbg_ptr = ptr;
        EnvProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EnvProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_envproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.envproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const GotFuncProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_gotfuncproxy_free(ptr >>> 0));
/**
*/
export class GotFuncProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(GotFuncProxy.prototype);
        obj.__wbg_ptr = ptr;
        GotFuncProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        GotFuncProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_gotfuncproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.gotfuncproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const GotMemProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_gotmemproxy_free(ptr >>> 0));
/**
*/
export class GotMemProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(GotMemProxy.prototype);
        obj.__wbg_ptr = ptr;
        GotMemProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        GotMemProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_gotmemproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.gotmemproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const WasiProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_wasiproxy_free(ptr >>> 0));
/**
*/
export class WasiProxy {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        WasiProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_wasiproxy_free(ptr);
    }
    /**
    * @param {any} _proxied_target
    * @param {any} property
    * @returns {any}
    */
    get(_proxied_target, property) {
        const ret = wasm.wasiproxy_get(this.__wbg_ptr, _proxied_target, property);
        return ret;
    }
}

export function __wbindgen_cb_drop(arg0) {
    const obj = arg0.original;
    if (obj.cnt-- == 1) {
        obj.a = 0;
        return true;
    }
    const ret = false;
    return ret;
};

export const __wbg_clearInterval_bd072ecb096d9775 = typeof clearInterval == 'function' ? clearInterval : notDefined('clearInterval');

export const __wbg_clearTimeout_541ac0980ffcef74 = typeof clearTimeout == 'function' ? clearTimeout : notDefined('clearTimeout');

export const __wbg_clearTimeout_76877dbc010e786d = typeof clearTimeout == 'function' ? clearTimeout : notDefined('clearTimeout');

export function __wbg_shift_7bda7eba309c9a1f(arg0) {
    const ret = arg0.shift();
    return ret;
};

export function __wbg_instanceof_ArrayBuffer_f4521cec1b99ee35(arg0) {
    let result;
    try {
        result = arg0 instanceof ArrayBuffer;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_new_ace717933ad7117f(arg0) {
    const ret = new Uint8Array(arg0);
    return ret;
};

export function __wbg_buffer_3da2aecfd9814cd8(arg0) {
    const ret = arg0.buffer;
    return ret;
};

export function __wbg_of_3d7aa62bb0ab56ee(arg0) {
    const ret = Array.of(arg0);
    return ret;
};

export function __wbg_postMessage_5e28bc78a4f37e0c() { return handleError(function (arg0, arg1, arg2) {
    arg0.postMessage(arg1, arg2);
}, arguments) };

export function __wbg_unshift_4671e53be53e8e39(arg0, arg1) {
    const ret = arg0.unshift(arg1);
    return ret;
};

export function __wbg_instanceof_OffscreenCanvas_aadc69c164da80c4(arg0) {
    let result;
    try {
        result = arg0 instanceof OffscreenCanvas;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_new_34c624469fb1d4fd() {
    const ret = new Array();
    return ret;
};

export function __wbg_data_5d6c23170bc379b2(arg0) {
    const ret = arg0.data;
    return ret;
};

export function __wbg_isArray_fbd24d447869b527(arg0) {
    const ret = Array.isArray(arg0);
    return ret;
};

export function __wbg_setInterval_edede8e2124cbb00() { return handleError(function (arg0, arg1) {
    const ret = setInterval(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_new_b7e038999edffb16() { return handleError(function () {
    const ret = new MessageChannel();
    return ret;
}, arguments) };

export function __wbg_port2_78f5a59a4effe9f7(arg0) {
    const ret = arg0.port2;
    return ret;
};

export function __wbg_new_87d841e70661f6e9() {
    const ret = new Object();
    return ret;
};

export function __wbg_start_ab1a682cca472112(arg0) {
    arg0.start();
};

export function __wbg_port1_55b3ea63b5d29a4d(arg0) {
    const ret = arg0.port1;
    return ret;
};

export function __wbg_setTimeout_75cb9b6991a4031d() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_instanceof_DedicatedWorkerGlobalScope_3e325e5a460a9ee6(arg0) {
    let result;
    try {
        result = arg0 instanceof DedicatedWorkerGlobalScope;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_setTimeout_7d81d052875b0f4f() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_Window_46abd570652cd5dc(arg0) {
    const ret = arg0.Window;
    return ret;
};

export function __wbindgen_is_undefined(arg0) {
    const ret = arg0 === undefined;
    return ret;
};

export function __wbg_fetch_0117c27c9b3739e0(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.fetch(v0);
    return ret;
};

export function __wbg_WorkerGlobalScope_2f6e7809f1b6ddc1(arg0) {
    const ret = arg0.WorkerGlobalScope;
    return ret;
};

export function __wbg_fetch_acb234cb2d211bf3(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.fetch(v0);
    return ret;
};

export function __wbg_instanceof_Response_0d25bb8436a9cefe(arg0) {
    let result;
    try {
        result = arg0 instanceof Response;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_status_351700a30c61ba61(arg0) {
    const ret = arg0.status;
    return ret;
};

export function __wbg_arrayBuffer_ec4617b29bb0f61c() { return handleError(function (arg0) {
    const ret = arg0.arrayBuffer();
    return ret;
}, arguments) };

export function __wbg_stringify_daa6661e90c04140() { return handleError(function (arg0) {
    const ret = JSON.stringify(arg0);
    return ret;
}, arguments) };

export function __wbindgen_string_get(arg0, arg1) {
    const obj = arg1;
    const ret = typeof(obj) === 'string' ? obj : undefined;
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_new_abda76e883ba8a5f() {
    const ret = new Error();
    return ret;
};

export function __wbg_stack_658279fe44541cf6(arg0, arg1) {
    const ret = arg1.stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_error_f851667af71bcfc6(arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
if (arg0 !== 0) { wasm.__wbindgen_free(arg0, arg1, 1); }
console.error(v0);
};

export function __wbg_subarray_7f7a652672800851(arg0, arg1, arg2) {
    const ret = arg0.subarray(arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_getRandomValues_7e42b4fb8779dc6d() { return handleError(function (arg0, arg1) {
    arg0.getRandomValues(arg1);
}, arguments) };

export function __wbindgen_memory() {
    const ret = wasm.memory;
    return ret;
};

export function __wbg_buffer_5d1b598a01b41a42(arg0) {
    const ret = arg0.buffer;
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_d695c7957788f922(arg0, arg1, arg2) {
    const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_randomFillSync_b70ccbdf4926a99d() { return handleError(function (arg0, arg1) {
    arg0.randomFillSync(arg1);
}, arguments) };

export function __wbg_crypto_d05b68a3572bb8ca(arg0) {
    const ret = arg0.crypto;
    return ret;
};

export function __wbindgen_is_object(arg0) {
    const val = arg0;
    const ret = typeof(val) === 'object' && val !== null;
    return ret;
};

export function __wbg_process_b02b3570280d0366(arg0) {
    const ret = arg0.process;
    return ret;
};

export function __wbg_versions_c1cb42213cedf0f5(arg0) {
    const ret = arg0.versions;
    return ret;
};

export function __wbg_node_43b1089f407e4ec2(arg0) {
    const ret = arg0.node;
    return ret;
};

export function __wbindgen_is_string(arg0) {
    const ret = typeof(arg0) === 'string';
    return ret;
};

export function __wbg_require_9a7e0f667ead4995() { return handleError(function () {
    const ret = module.require;
    return ret;
}, arguments) };

export function __wbindgen_is_function(arg0) {
    const ret = typeof(arg0) === 'function';
    return ret;
};

export function __wbindgen_string_new(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return ret;
};

export function __wbg_call_f6a2bc58c19c53c6() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.call(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_msCrypto_10fc94afee92bd76(arg0) {
    const ret = arg0.msCrypto;
    return ret;
};

export function __wbg_newwithlength_728575f3bba9959b(arg0) {
    const ret = new Uint8Array(arg0 >>> 0);
    return ret;
};

export function __wbg_texSubImage2D_4d372d780fc0e4a7() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texSubImage2D_cbc346dc5a210f5d() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texSubImage2D_ad0af504139d876c() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texSubImage3D_e36d3c30ac0d0749() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.texSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0, arg11);
}, arguments) };

export function __wbg_texSubImage3D_d98b6d6d4c3f3d01() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.texSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0, arg11);
}, arguments) };

export function __wbg_texSubImage3D_a8f081c484f78039() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.texSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0, arg11);
}, arguments) };

export function __wbg_createFramebuffer_f8c26154f8992bfa(arg0) {
    const ret = arg0.createFramebuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createFramebuffer_52e5d7327d5afba3(arg0) {
    const ret = arg0.createFramebuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createShader_896229165c5a11d4(arg0, arg1) {
    const ret = arg0.createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createShader_119ffcdb1667f405(arg0, arg1) {
    const ret = arg0.createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createTexture_b77eefdce0bb2c55(arg0) {
    const ret = arg0.createTexture();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createTexture_4f0c3c77df4bde11(arg0) {
    const ret = arg0.createTexture();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_deleteShader_5ec1e25476df2da0(arg0, arg1) {
    arg0.deleteShader(arg1);
};

export function __wbg_deleteShader_322b059ad560664a(arg0, arg1) {
    arg0.deleteShader(arg1);
};

export function __wbg_shaderSource_04af20ecb1962b3b(arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    arg0.shaderSource(arg1, v0);
};

export function __wbg_shaderSource_e12efd3a2bf3413d(arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    arg0.shaderSource(arg1, v0);
};

export function __wbg_compileShader_be824cfad43331b8(arg0, arg1) {
    arg0.compileShader(arg1);
};

export function __wbg_compileShader_bdfb3d5a3ad59498(arg0, arg1) {
    arg0.compileShader(arg1);
};

export function __wbg_getShaderParameter_a9315ba73ab18731(arg0, arg1, arg2) {
    const ret = arg0.getShaderParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbg_getShaderParameter_58d3b34afa9db13b(arg0, arg1, arg2) {
    const ret = arg0.getShaderParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbindgen_boolean_get(arg0) {
    const v = arg0;
    const ret = typeof(v) === 'boolean' ? (v ? 1 : 0) : 2;
    return ret;
};

export function __wbg_getShaderInfoLog_3e435d2b50e0ecf0(arg0, arg1, arg2) {
    const ret = arg1.getShaderInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_getShaderInfoLog_562b1447e7c24866(arg0, arg1, arg2) {
    const ret = arg1.getShaderInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_createProgram_983b87cad6d06768(arg0) {
    const ret = arg0.createProgram();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createProgram_0a7670ed33f06d97(arg0) {
    const ret = arg0.createProgram();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_deleteProgram_d90e44574acb8018(arg0, arg1) {
    arg0.deleteProgram(arg1);
};

export function __wbg_deleteProgram_8447c337271aa934(arg0, arg1) {
    arg0.deleteProgram(arg1);
};

export function __wbg_attachShader_cfbbdefc08a0422f(arg0, arg1, arg2) {
    arg0.attachShader(arg1, arg2);
};

export function __wbg_attachShader_70c3f88b777a0a54(arg0, arg1, arg2) {
    arg0.attachShader(arg1, arg2);
};

export function __wbg_linkProgram_caeab1eb0c0246be(arg0, arg1) {
    arg0.linkProgram(arg1);
};

export function __wbg_linkProgram_e170ffe0b8242136(arg0, arg1) {
    arg0.linkProgram(arg1);
};

export function __wbg_getProgramParameter_35800b92324ff726(arg0, arg1, arg2) {
    const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbg_getProgramParameter_22b3f1c8d913cd2c(arg0, arg1, arg2) {
    const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbg_getProgramInfoLog_3ff10ea818ab6ce4(arg0, arg1, arg2) {
    const ret = arg1.getProgramInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_getProgramInfoLog_110f43b4125782e9(arg0, arg1, arg2) {
    const ret = arg1.getProgramInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_useProgram_53de6b084c4780ce(arg0, arg1) {
    arg0.useProgram(arg1);
};

export function __wbg_useProgram_229c8fa8394b4c26(arg0, arg1) {
    arg0.useProgram(arg1);
};

export function __wbg_createBuffer_90bf79c414ad4956(arg0) {
    const ret = arg0.createBuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createBuffer_a95c59cc2c1750e7(arg0) {
    const ret = arg0.createBuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_bindBuffer_ac939bcab5249160(arg0, arg1, arg2) {
    arg0.bindBuffer(arg1 >>> 0, arg2);
};

export function __wbg_bindBuffer_3f166cc2f502fc09(arg0, arg1, arg2) {
    arg0.bindBuffer(arg1 >>> 0, arg2);
};

export function __wbg_bindFramebuffer_0b8b88d70f0b876e(arg0, arg1, arg2) {
    arg0.bindFramebuffer(arg1 >>> 0, arg2);
};

export function __wbg_bindFramebuffer_28e8c0c3f76447af(arg0, arg1, arg2) {
    arg0.bindFramebuffer(arg1 >>> 0, arg2);
};

export function __wbg_bindRenderbuffer_f06f73fc0b43967e(arg0, arg1, arg2) {
    arg0.bindRenderbuffer(arg1 >>> 0, arg2);
};

export function __wbg_bindRenderbuffer_2fe89083883b96e7(arg0, arg1, arg2) {
    arg0.bindRenderbuffer(arg1 >>> 0, arg2);
};

export function __wbg_blitFramebuffer_8ca764978b2e3b3f(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    arg0.blitFramebuffer(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0);
};

export function __wbg_clearColor_9a45e2200c61a8f2(arg0, arg1, arg2, arg3, arg4) {
    arg0.clearColor(arg1, arg2, arg3, arg4);
};

export function __wbg_clearColor_b48ee3ca810de959(arg0, arg1, arg2, arg3, arg4) {
    arg0.clearColor(arg1, arg2, arg3, arg4);
};

export function __wbg_clearDepth_a40e7b975ebc5c14(arg0, arg1) {
    arg0.clearDepth(arg1);
};

export function __wbg_clearDepth_3777869cc4be970c(arg0, arg1) {
    arg0.clearDepth(arg1);
};

export function __wbg_clearStencil_62277af75c0a3558(arg0, arg1) {
    arg0.clearStencil(arg1);
};

export function __wbg_clearStencil_49cd65640cc9d1d9(arg0, arg1) {
    arg0.clearStencil(arg1);
};

export function __wbg_clear_af4278a00382d3ce(arg0, arg1) {
    arg0.clear(arg1 >>> 0);
};

export function __wbg_clear_40335e7899ec7759(arg0, arg1) {
    arg0.clear(arg1 >>> 0);
};

export function __wbg_pixelStorei_ac98844c2d6d1937(arg0, arg1, arg2) {
    arg0.pixelStorei(arg1 >>> 0, arg2);
};

export function __wbg_pixelStorei_6be3fc7114b690b8(arg0, arg1, arg2) {
    arg0.pixelStorei(arg1 >>> 0, arg2);
};

export function __wbg_bufferData_9566a2faddca5792(arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
};

export function __wbg_bufferData_6c5edae24f952d4d(arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
};

export function __wbg_bufferSubData_7d216abec8307331(arg0, arg1, arg2, arg3) {
    arg0.bufferSubData(arg1 >>> 0, arg2, arg3);
};

export function __wbg_bufferSubData_3b75566851019327(arg0, arg1, arg2, arg3) {
    arg0.bufferSubData(arg1 >>> 0, arg2, arg3);
};

export function __wbg_copyBufferSubData_70becf455ca484cd(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.copyBufferSubData(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
};

export function __wbg_copyTexSubImage2D_6ce49c4a307e877d(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
    arg0.copyTexSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8);
};

export function __wbg_copyTexSubImage2D_6e2fe88bb9fa3ffd(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
    arg0.copyTexSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8);
};

export function __wbg_copyTexSubImage3D_f385cc4e05c95e64(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.copyTexSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9);
};

export function __wbg_deleteBuffer_b8aaa61f9bb13617(arg0, arg1) {
    arg0.deleteBuffer(arg1);
};

export function __wbg_deleteBuffer_d70596808095dac2(arg0, arg1) {
    arg0.deleteBuffer(arg1);
};

export function __wbg_deleteFramebuffer_d6907809466bdbdb(arg0, arg1) {
    arg0.deleteFramebuffer(arg1);
};

export function __wbg_deleteFramebuffer_23c9c7c8176aa9b8(arg0, arg1) {
    arg0.deleteFramebuffer(arg1);
};

export function __wbg_deleteSync_6bff1584a3aae6a1(arg0, arg1) {
    arg0.deleteSync(arg1);
};

export function __wbg_deleteTexture_554c30847d340929(arg0, arg1) {
    arg0.deleteTexture(arg1);
};

export function __wbg_deleteTexture_bbda7cb554bc12b9(arg0, arg1) {
    arg0.deleteTexture(arg1);
};

export function __wbg_disable_57e8624c865bd654(arg0, arg1) {
    arg0.disable(arg1 >>> 0);
};

export function __wbg_disable_f68719f70ddfb5b8(arg0, arg1) {
    arg0.disable(arg1 >>> 0);
};

export function __wbg_drawArrays_d48ee5c0a02be869(arg0, arg1, arg2, arg3) {
    arg0.drawArrays(arg1 >>> 0, arg2, arg3);
};

export function __wbg_drawArrays_2f37c32534dffd91(arg0, arg1, arg2, arg3) {
    arg0.drawArrays(arg1 >>> 0, arg2, arg3);
};

export function __wbindgen_number_new(arg0) {
    const ret = arg0;
    return ret;
};

export function __wbg_push_906164999551d793(arg0, arg1) {
    const ret = arg0.push(arg1);
    return ret;
};

export function __wbg_drawBuffersWEBGL_533ee2b72ddb728a(arg0, arg1) {
    arg0.drawBuffersWEBGL(arg1);
};

export function __wbg_drawBuffers_117fa4691357b53d(arg0, arg1) {
    arg0.drawBuffers(arg1);
};

export function __wbg_enable_54d01bacc240df3e(arg0, arg1) {
    arg0.enable(arg1 >>> 0);
};

export function __wbg_enable_6dab9d5278ba15e2(arg0, arg1) {
    arg0.enable(arg1 >>> 0);
};

export function __wbg_framebufferTexture2D_e7783c0015d1135a(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTexture2D(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4, arg5);
};

export function __wbg_framebufferTexture2D_c61bc6c888f33a52(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTexture2D(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4, arg5);
};

export function __wbg_framebufferTextureLayer_5fdc631245f13684(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTextureLayer(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
};

export function __wbindgen_number_get(arg0, arg1) {
    const obj = arg1;
    const ret = typeof(obj) === 'number' ? obj : undefined;
    getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
    getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
};

export function __wbg_getIndexedParameter_69fe97ab84f9db9b() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.getIndexedParameter(arg1 >>> 0, arg2 >>> 0);
    return ret;
}, arguments) };

export function __wbg_getUniformLocation_f161344f25983444(arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    const ret = arg0.getUniformLocation(arg1, v0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_getUniformLocation_7b435a76db4f3128(arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    const ret = arg0.getUniformLocation(arg1, v0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_samplerParameterf_a15f79d315dcfc5d(arg0, arg1, arg2, arg3) {
    arg0.samplerParameterf(arg1, arg2 >>> 0, arg3);
};

export function __wbg_samplerParameteri_6f5c08b9c98433e9(arg0, arg1, arg2, arg3) {
    arg0.samplerParameteri(arg1, arg2 >>> 0, arg3);
};

export function __wbg_texImage2D_9cd1931c442b03ad() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texImage2D_1159b898accc2807() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_newwithbyteoffsetandlength_2412e38a0385bbe2(arg0, arg1, arg2) {
    const ret = new Uint16Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_16ba6d10861ea013(arg0, arg1, arg2) {
    const ret = new Int16Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_821c7736f0d22b04(arg0, arg1, arg2) {
    const ret = new Int32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_aeed38cac7555df7(arg0, arg1, arg2) {
    const ret = new Uint32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_21163b4dfcbc673c(arg0, arg1, arg2) {
    const ret = new Float32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_texImage3D_8387d089d2edabd3() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    arg0.texImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8 >>> 0, arg9 >>> 0, arg10);
}, arguments) };

export function __wbg_texStorage2D_b46c4dcaa6dc9638(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.texStorage2D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export function __wbg_uniform1i_5a5f1f9d5828e6c6(arg0, arg1, arg2) {
    arg0.uniform1i(arg1, arg2);
};

export function __wbg_uniform1i_1fd90743f7b78faa(arg0, arg1, arg2) {
    arg0.uniform1i(arg1, arg2);
};

export function __wbg_uniform2iv_5ba0883cf01ae09d(arg0, arg1, arg2, arg3) {
    arg0.uniform2iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform2iv_fcef57681e7795f1(arg0, arg1, arg2, arg3) {
    arg0.uniform2iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform3iv_f297f19f134ad0c2(arg0, arg1, arg2, arg3) {
    arg0.uniform3iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform3iv_da537ca1568e83fe(arg0, arg1, arg2, arg3) {
    arg0.uniform3iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform4iv_2dbb8a34d36a28c3(arg0, arg1, arg2, arg3) {
    arg0.uniform4iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform4iv_eaebe8f50f18f893(arg0, arg1, arg2, arg3) {
    arg0.uniform4iv(arg1, getArrayI32FromWasm0(arg2, arg3));
};

export function __wbg_uniform1ui_c80628cb3caeb621(arg0, arg1, arg2) {
    arg0.uniform1ui(arg1, arg2 >>> 0);
};

export function __wbg_uniform2uiv_71554e4167cdd33e(arg0, arg1, arg2, arg3) {
    arg0.uniform2uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
};

export function __wbg_uniform3uiv_428937cb43fae771(arg0, arg1, arg2, arg3) {
    arg0.uniform3uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
};

export function __wbg_uniform4uiv_16e6176d8af58a26(arg0, arg1, arg2, arg3) {
    arg0.uniform4uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
};

export function __wbg_uniform1f_5c36f8a2cf1d8cd7(arg0, arg1, arg2) {
    arg0.uniform1f(arg1, arg2);
};

export function __wbg_uniform1f_b13c736354a10aa7(arg0, arg1, arg2) {
    arg0.uniform1f(arg1, arg2);
};

export function __wbg_uniform2fv_d375e6a7b2f1e575(arg0, arg1, arg2, arg3) {
    arg0.uniform2fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniform2fv_a079de4d57adc89f(arg0, arg1, arg2, arg3) {
    arg0.uniform2fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniform3fv_ce5f4b99b178dd74(arg0, arg1, arg2, arg3) {
    arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniform3fv_0211c4807ed5b6bb(arg0, arg1, arg2, arg3) {
    arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniform4fv_f7afb7d09ee03175(arg0, arg1, arg2, arg3) {
    arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniform4fv_5134ae6d977cd056(arg0, arg1, arg2, arg3) {
    arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
};

export function __wbg_uniformMatrix2fv_9e0249ce783ce2be(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix2fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix2fv_1c4f6d47a69eddf2(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix2fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix2x3fv_b020ec69dab7967a(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix2x3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix2x4fv_95bdc38e1581b61c(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix2x4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix3x2fv_9fb4b6d3e6773824(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3x2fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix3fv_2a9524cf34ecbd62(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix3fv_5b337adcad4a038d(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix3x4fv_0fa64821be97c8f2(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3x4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix4x2fv_b40bad492503453e(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4x2fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix4x3fv_2571917be5ea974c(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4x3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix4fv_4c466deaf158ed5b(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_uniformMatrix4fv_10075e61e88aea3b(arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export function __wbg_cullFace_a65f5d17b1ff5686(arg0, arg1) {
    arg0.cullFace(arg1 >>> 0);
};

export function __wbg_cullFace_68b06ff8967b93f3(arg0, arg1) {
    arg0.cullFace(arg1 >>> 0);
};

export function __wbg_colorMask_57603facaeb6e2e3(arg0, arg1, arg2, arg3, arg4) {
    arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
};

export function __wbg_colorMask_743f2bbb6e3fb4e5(arg0, arg1, arg2, arg3, arg4) {
    arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
};

export function __wbg_depthMask_d2c08d83ea550563(arg0, arg1) {
    arg0.depthMask(arg1 !== 0);
};

export function __wbg_depthMask_052a5e3afe45b590(arg0, arg1) {
    arg0.depthMask(arg1 !== 0);
};

export function __wbg_invalidateFramebuffer_2d3e8a1b99fd845c() { return handleError(function (arg0, arg1, arg2) {
    arg0.invalidateFramebuffer(arg1 >>> 0, arg2);
}, arguments) };

export function __wbg_polygonOffset_442517f9de53e3de(arg0, arg1, arg2) {
    arg0.polygonOffset(arg1, arg2);
};

export function __wbg_polygonOffset_0f2730043ba169b2(arg0, arg1, arg2) {
    arg0.polygonOffset(arg1, arg2);
};

export function __wbg_bindTexture_e28115f3ea3da6d2(arg0, arg1, arg2) {
    arg0.bindTexture(arg1 >>> 0, arg2);
};

export function __wbg_bindTexture_be92cdd3f162b4f9(arg0, arg1, arg2) {
    arg0.bindTexture(arg1 >>> 0, arg2);
};

export function __wbg_bindSampler_12a1965a2db071ed(arg0, arg1, arg2) {
    arg0.bindSampler(arg1 >>> 0, arg2);
};

export function __wbg_activeTexture_3748123e1becf07d(arg0, arg1) {
    arg0.activeTexture(arg1 >>> 0);
};

export function __wbg_activeTexture_02d56293bce2f613(arg0, arg1) {
    arg0.activeTexture(arg1 >>> 0);
};

export function __wbg_texParameteri_dd08984388e62491(arg0, arg1, arg2, arg3) {
    arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
};

export function __wbg_texParameteri_f5c0d085b77931dd(arg0, arg1, arg2, arg3) {
    arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
};

export function __wbg_texSubImage2D_d23a3ec1fa60bdaf() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texSubImage2D_33018bcf2de70890() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_texSubImage2D_b97aa5ddc0162314() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
}, arguments) };

export function __wbg_compressedTexSubImage2D_5666e0146e152b7d(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
    arg0.compressedTexSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8);
};

export function __wbg_compressedTexSubImage2D_41270fc03b157293(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
    arg0.compressedTexSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8);
};

export function __wbg_compressedTexSubImage2D_1194f18adf8859b9(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
    arg0.compressedTexSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8, arg9);
};

export function __wbg_texSubImage3D_5884c8e282839ff9() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.texSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0, arg11);
}, arguments) };

export function __wbg_texSubImage3D_2742ec6099cae3fc() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.texSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0, arg11);
}, arguments) };

export function __wbg_compressedTexSubImage3D_34cd53cffc6add9c(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) {
    arg0.compressedTexSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10, arg11);
};

export function __wbg_compressedTexSubImage3D_f0da020d6e3e3791(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    arg0.compressedTexSubImage3D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10);
};

export function __wbg_vertexAttribPointer_e9c4ff85658b9ad2(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.vertexAttribPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4 !== 0, arg5, arg6);
};

export function __wbg_vertexAttribPointer_3133080603a92d4c(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.vertexAttribPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4 !== 0, arg5, arg6);
};

export function __wbg_vertexAttribIPointer_24c9254053dd8ab4(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.vertexAttribIPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export function __wbg_viewport_0ca27d1d6ac8424c(arg0, arg1, arg2, arg3, arg4) {
    arg0.viewport(arg1, arg2, arg3, arg4);
};

export function __wbg_viewport_afd5166081d009b2(arg0, arg1, arg2, arg3, arg4) {
    arg0.viewport(arg1, arg2, arg3, arg4);
};

export function __wbg_blendFunc_5adf0a3a9f164e6e(arg0, arg1, arg2) {
    arg0.blendFunc(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_blendFunc_ac53b0d3a97b7f7f(arg0, arg1, arg2) {
    arg0.blendFunc(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_blendFuncSeparate_52fdb0f1fbf57928(arg0, arg1, arg2, arg3, arg4) {
    arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
};

export function __wbg_blendFuncSeparate_b6a96b8e26e75171(arg0, arg1, arg2, arg3, arg4) {
    arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
};

export function __wbg_blendEquation_b1df5434f3ad5aac(arg0, arg1) {
    arg0.blendEquation(arg1 >>> 0);
};

export function __wbg_blendEquation_1c7272d8e9e0ce11(arg0, arg1) {
    arg0.blendEquation(arg1 >>> 0);
};

export function __wbg_blendEquationSeparate_33f23a57d77e8079(arg0, arg1, arg2) {
    arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_blendEquationSeparate_457e81472270e23c(arg0, arg1, arg2) {
    arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_next_267398d0e0761bf9() { return handleError(function (arg0) {
    const ret = arg0.next();
    return ret;
}, arguments) };

export function __wbg_done_506b44765ba84b9c(arg0) {
    const ret = arg0.done;
    return ret;
};

export function __wbg_value_31485d8770eb06ab(arg0) {
    const ret = arg0.value;
    return ret;
};

export function __wbg_self_086b5302bcafb962() { return handleError(function () {
    const ret = self.self;
    return ret;
}, arguments) };

export function __wbg_window_132fa5d7546f1de5() { return handleError(function () {
    const ret = window.window;
    return ret;
}, arguments) };

export function __wbg_globalThis_e5f801a37ad7d07b() { return handleError(function () {
    const ret = globalThis.globalThis;
    return ret;
}, arguments) };

export function __wbg_global_f9a61fce4af6b7c1() { return handleError(function () {
    const ret = global.global;
    return ret;
}, arguments) };

export function __wbg_newnoargs_5859b6d41c6fe9f7(arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = new Function(v0);
    return ret;
};

export function __wbg_call_a79f1973a4f07d5e() { return handleError(function (arg0, arg1) {
    const ret = arg0.call(arg1);
    return ret;
}, arguments) };

export function __wbg_set_37a50e901587b477() { return handleError(function (arg0, arg1, arg2) {
    const ret = Reflect.set(arg0, arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_newwithbyteoffsetandlength_54c7b98977affdec(arg0, arg1, arg2) {
    const ret = new Int8Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_set_74906aa30864df5a(arg0, arg1, arg2) {
    arg0.set(arg1, arg2 >>> 0);
};

export function __wbg_length_f0764416ba5bb237(arg0) {
    const ret = arg0.length;
    return ret;
};

export const __wbg_random_07b2df1a129f4f17 = typeof Math.random == 'function' ? Math.random : notDefined('Math.random');

export function __wbg_new_ad731e6c8cecb940(arg0) {
    const ret = new Int32Array(arg0);
    return ret;
};

export function __wbg_waitAsync_2151fdbb582576c7() { return handleError(function (arg0, arg1, arg2) {
    const ret = Atomics.waitAsync(arg0, arg1 >>> 0, arg2);
    return ret;
}, arguments) };

export function __wbg_store_3b88fdd05b11b2f4() { return handleError(function (arg0, arg1, arg2) {
    const ret = Atomics.store(arg0, arg1 >>> 0, arg2);
    return ret;
}, arguments) };

export function __wbg_get_5027b32da70f39b1() { return handleError(function (arg0, arg1) {
    const ret = Reflect.get(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_instanceof_Promise_bbd46d56519fb966(arg0) {
    let result;
    try {
        result = arg0 instanceof Promise;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_new_8be2c974d27d6540(arg0) {
    const ret = new Uint32Array(arg0);
    return ret;
};

export function __wbg_getindex_b53650d0f6728640(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
};

export function __wbg_slice_68a7b6c56027340a(arg0, arg1, arg2) {
    const ret = arg0.slice(arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_new_393d9e609f8faae8() { return handleError(function (arg0) {
    const ret = new WebAssembly.Module(arg0);
    return ret;
}, arguments) };

export const __wbg_compile_e698742d842b9f89 = typeof WebAssembly.compile == 'function' ? WebAssembly.compile : notDefined('WebAssembly.compile');

export function __wbg_customSections_9f9871398b2ad791(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = WebAssembly.Module.customSections(arg0, v0);
    return ret;
};

export function __wbg_getindex_0462906f06036feb(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
};

export function __wbg_fill_ed90af5e9a810d6b(arg0, arg1, arg2, arg3) {
    const ret = arg0.fill(arg1, arg2 >>> 0, arg3 >>> 0);
    return ret;
};

export function __wbg_length_0c5d20eba514ad27(arg0) {
    const ret = arg0.length;
    return ret;
};

export function __wbg_setindex_20460e6d055b25ca(arg0, arg1, arg2) {
    arg0[arg1 >>> 0] = arg2;
};

export function __wbg_setindex_bb9a17757fb5089c(arg0, arg1, arg2) {
    arg0[arg1 >>> 0] = arg2 >>> 0;
};

export function __wbg_grow_69302b85cfc290e3() { return handleError(function (arg0, arg1) {
    const ret = arg0.grow(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbg_new_c50681e5736aeae8() { return handleError(function (arg0, arg1) {
    const ret = new WebAssembly.Global(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_envproxy_new(arg0) {
    const ret = EnvProxy.__wrap(arg0);
    return ret;
};

export function __wbg_instanceof_Object_06e0ec0f1056bcd5(arg0) {
    let result;
    try {
        result = arg0 instanceof Object;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_bind_ec554c9733f96765(arg0, arg1) {
    const ret = arg0.bind(arg1);
    return ret;
};

export function __wbg_new_145b4fea3cafc601(arg0, arg1) {
    const ret = new Proxy(arg0, arg1);
    return ret;
};

export function __wbg_gotmemproxy_new(arg0) {
    const ret = GotMemProxy.__wrap(arg0);
    return ret;
};

export function __wbg_gotfuncproxy_new(arg0) {
    const ret = GotFuncProxy.__wrap(arg0);
    return ret;
};

export function __wbg_new_c8841ffc76bb8f0e() { return handleError(function (arg0, arg1) {
    const ret = new WebAssembly.Instance(arg0, arg1);
    return ret;
}, arguments) };

export const __wbg_instantiate_4969234dd921d59a = typeof WebAssembly.instantiate == 'function' ? WebAssembly.instantiate : notDefined('WebAssembly.instantiate');

export function __wbg_get_ca324e8b9d32bbb3() { return handleError(function (arg0, arg1) {
    const ret = arg0.get(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbindgen_is_null(arg0) {
    const ret = arg0 === null;
    return ret;
};

export function __wbg_set_608725b060eff00f(arg0, arg1, arg2) {
    const ret = arg0.set(arg1, arg2);
    return ret;
};

export function __wbg_exports_f39b8faa22512b2b(arg0) {
    const ret = arg0.exports;
    return ret;
};

export function __wbg_entries_7a47f5716366056b(arg0) {
    const ret = Object.entries(arg0);
    return ret;
};

export function __wbg_values_705f0abfa0ca41f6(arg0) {
    const ret = arg0.values();
    return ret;
};

export function __wbg_values_2415d2dc53bb82f7(arg0) {
    const ret = Object.values(arg0);
    return ret;
};

export function __wbg_instanceof_Global_9f335e05e259aead(arg0) {
    let result;
    try {
        result = arg0 instanceof Global;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_value_3ec6fba02c5d720e(arg0) {
    const ret = arg0.value;
    return ret;
};

export function __wbindgen_is_falsy(arg0) {
    const ret = !arg0;
    return ret;
};

export function __wbg_setvalue_f2d61c408b793101(arg0, arg1) {
    arg0.value = arg1;
};

export function __wbg_get_0a2c1a6b91ea556c(arg0, arg1) {
    const ret = arg0.get(arg1);
    return ret;
};

export function __wbg_set_b4585b071bc2a64d() { return handleError(function (arg0, arg1, arg2) {
    arg0.set(arg1 >>> 0, arg2);
}, arguments) };

export function __wbg_now_86f7ca537c8b86d5() {
    const ret = Date.now();
    return ret;
};

export function __wbg_bind_3dc1c2b6f6a7d461(arg0, arg1, arg2, arg3) {
    const ret = arg0.bind(arg1, arg2, arg3);
    return ret;
};

export function __wbg_call_84a3935b24cb0625() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    const ret = arg0.call(arg1, arg2, arg3, arg4);
    return ret;
}, arguments) };

export function __wbg_timeOrigin_03715741c9ab035d(arg0) {
    const ret = arg0.timeOrigin;
    return ret;
};

export function __wbg_now_65ff8ec2b863300c(arg0) {
    const ret = arg0.now();
    return ret;
};

export function __wbg_apply_d890a2eae27f4a03() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.apply(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_grow_43df94948cf95609(arg0, arg1) {
    const ret = arg0.grow(arg1 >>> 0);
    return ret;
};

export function __wbg_call_5830eb5e103aa772() { return handleError(function (arg0, arg1, arg2, arg3) {
    const ret = arg0.call(arg1, arg2, arg3);
    return ret;
}, arguments) };

export function __wbg_navigator_fbab58a088920616(arg0) {
    const ret = arg0.navigator;
    return ret;
};

export function __wbg_userAgent_a334df34d9cf596f() { return handleError(function (arg0, arg1) {
    const ret = arg1.userAgent;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
}, arguments) };

export const __wbg_error_1f4e3e298a7c97f6 = typeof console.error == 'function' ? console.error : notDefined('console.error');

export const __wbg_debug_81bf1b6b83cc1a06 = typeof console.debug == 'function' ? console.debug : notDefined('console.debug');

export const __wbg_info_24b7c0f9d7eb6623 = typeof console.info == 'function' ? console.info : notDefined('console.info');

export const __wbg_warn_0e0204547af47087 = typeof console.warn == 'function' ? console.warn : notDefined('console.warn');

export function __wbindgen_debug_string(arg0, arg1) {
    const ret = debugString(arg1);
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export function __wbg_then_7aeb7c5f1536640f(arg0, arg1) {
    const ret = arg0.then(arg1);
    return ret;
};

export const __wbg_queueMicrotask_118eeb525d584d9a = typeof queueMicrotask == 'function' ? queueMicrotask : notDefined('queueMicrotask');

export function __wbg_then_5842e4e97f7beace(arg0, arg1, arg2) {
    const ret = arg0.then(arg1, arg2);
    return ret;
};

export function __wbg_queueMicrotask_26a89c14c53809c0(arg0) {
    const ret = arg0.queueMicrotask;
    return ret;
};

export function __wbg_resolve_97ecd55ee839391b(arg0) {
    const ret = Promise.resolve(arg0);
    return ret;
};

export function __wbg_addEventListener_1b158e9e95e0ab00() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.addEventListener(v0, arg3, arg4);
}, arguments) };

export function __wbg_removeEventListener_177ff96081e6f22d() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.removeEventListener(v0, arg3, arg4 !== 0);
}, arguments) };

export function __wbg_getExtension_25430e0ed157fcf8() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.getExtension(v0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
}, arguments) };

export function __wbg_getParameter_b282105ca8420119() { return handleError(function (arg0, arg1) {
    const ret = arg0.getParameter(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbg_getParameter_798cbb8ff20c7af0() { return handleError(function (arg0, arg1) {
    const ret = arg0.getParameter(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbg_now_e0d8ec93dd25766a(arg0) {
    const ret = arg0.now();
    return ret;
};

export function __wbg_performance_eeefc685c9bc38b4(arg0) {
    const ret = arg0.performance;
    return ret;
};

export function __wbg_deleteVertexArray_26631f33de66bdfd(arg0, arg1) {
    arg0.deleteVertexArray(arg1);
};

export function __wbg_deleteVertexArrayOES_657b2572282b9dff(arg0, arg1) {
    arg0.deleteVertexArrayOES(arg1);
};

export function __wbg_deleteQuery_3524b489c741d48f(arg0, arg1) {
    arg0.deleteQuery(arg1);
};

export function __wbg_deleteSampler_f760c2bdc7a3d881(arg0, arg1) {
    arg0.deleteSampler(arg1);
};

export function __wbg_fenceSync_452ae6f3789bdc77(arg0, arg1, arg2) {
    const ret = arg0.fenceSync(arg1 >>> 0, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createVertexArrayOES_96ccfea00081dcf3(arg0) {
    const ret = arg0.createVertexArrayOES();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createVertexArray_761ba22fc5da3ad7(arg0) {
    const ret = arg0.createVertexArray();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_bindVertexArrayOES_e95cf32f50e47240(arg0, arg1) {
    arg0.bindVertexArrayOES(arg1);
};

export function __wbg_bindVertexArray_2a70aed123353597(arg0, arg1) {
    arg0.bindVertexArray(arg1);
};

export function __wbg_bufferData_b2e68fdc1fd1e94b(arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
};

export function __wbg_bufferData_eab63186e3e72d98(arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
};

export function __wbg_instanceof_Window_99dc9805eaa2614b(arg0) {
    let result;
    try {
        result = arg0 instanceof Window;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_document_5257b70811e953c0(arg0) {
    const ret = arg0.document;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_querySelector_d86f889797c65e88() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.querySelector(v0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
}, arguments) };

export function __wbg_instanceof_HtmlCanvasElement_a6076360513b6876(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLCanvasElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_getContext_f183e180a122d091() { return handleError(function (arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.getContext(v0, arg3);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
}, arguments) };

export function __wbg_getContext_1daf9aba3e114993() { return handleError(function (arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.getContext(v0, arg3);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
}, arguments) };

export function __wbg_createSampler_e2bcf2bc717a1cad(arg0) {
    const ret = arg0.createSampler();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createQuery_dca7163929abd29d(arg0) {
    const ret = arg0.createQuery();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_videoWidth_024256de61021e4a(arg0) {
    const ret = arg0.videoWidth;
    return ret;
};

export function __wbg_videoHeight_2c601663d2d0211a(arg0) {
    const ret = arg0.videoHeight;
    return ret;
};

export function __wbg_width_9d9d26b087c6ad54(arg0) {
    const ret = arg0.width;
    return ret;
};

export function __wbg_height_770da314320603d8(arg0) {
    const ret = arg0.height;
    return ret;
};

export function __wbg_width_193b434156effb1d(arg0) {
    const ret = arg0.width;
    return ret;
};

export function __wbg_height_84d4ae4d422188a3(arg0) {
    const ret = arg0.height;
    return ret;
};

export function __wbg_width_05e7fce75535d85f(arg0) {
    const ret = arg0.width;
    return ret;
};

export function __wbg_height_51b9308e888df865(arg0) {
    const ret = arg0.height;
    return ret;
};

export function __wbg_is_a5728dbfb61c82cd(arg0, arg1) {
    const ret = Object.is(arg0, arg1);
    return ret;
};

export function __wbg_instanceof_WebGl2RenderingContext_92adf5bbd2568b71(arg0) {
    let result;
    try {
        result = arg0 instanceof WebGL2RenderingContext;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_getSupportedExtensions_1a007030d26efba5(arg0) {
    const ret = arg0.getSupportedExtensions();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_length_d99b680fd68bf71b(arg0) {
    const ret = arg0.length;
    return ret;
};

export function __wbg_get_c43534c00f382c8a(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
};

export function __wbg_getSupportedProfiles_a3af04122b4f2f30(arg0) {
    const ret = arg0.getSupportedProfiles();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_includes_b0feae2b4a1ae514(arg0, arg1, arg2) {
    const ret = arg0.includes(arg1, arg2);
    return ret;
};

export function __wbg_setwidth_05075fb6b4cc720e(arg0, arg1) {
    arg0.width = arg1 >>> 0;
};

export function __wbg_setheight_7e0e88a922100d8c(arg0, arg1) {
    arg0.height = arg1 >>> 0;
};

export function __wbg_setwidth_62ca8c8f2794be77(arg0, arg1) {
    arg0.width = arg1 >>> 0;
};

export function __wbg_setheight_34b71cfdf6095cbd(arg0, arg1) {
    arg0.height = arg1 >>> 0;
};

export function __wbg_getBufferSubData_42fbdf01d4c31560(arg0, arg1, arg2, arg3) {
    arg0.getBufferSubData(arg1 >>> 0, arg2, arg3);
};

export function __wbg_createRenderbuffer_e2d77844fbdcc842(arg0) {
    const ret = arg0.createRenderbuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_createRenderbuffer_881be806709189a9(arg0) {
    const ret = arg0.createRenderbuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_renderbufferStorage_982fcb5577f764ca(arg0, arg1, arg2, arg3, arg4) {
    arg0.renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
};

export function __wbg_renderbufferStorage_5a63960c0bb41916(arg0, arg1, arg2, arg3, arg4) {
    arg0.renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
};

export function __wbg_renderbufferStorageMultisample_c5f884a4faf6330a(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.renderbufferStorageMultisample(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export function __wbg_texStorage3D_521eded8d8da33a6(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.texStorage3D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5, arg6);
};

export function __wbg_deleteRenderbuffer_1c4b186beb91d4a5(arg0, arg1) {
    arg0.deleteRenderbuffer(arg1);
};

export function __wbg_deleteRenderbuffer_7bb3c4c79eac08ff(arg0, arg1) {
    arg0.deleteRenderbuffer(arg1);
};

export function __wbg_bindAttribLocation_1d7075153fbbd1e4(arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg3, arg4);
    arg0.bindAttribLocation(arg1, arg2 >>> 0, v0);
};

export function __wbg_bindAttribLocation_ff0dc5b546d9c8b0(arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg3, arg4);
    arg0.bindAttribLocation(arg1, arg2 >>> 0, v0);
};

export function __wbg_getUniformBlockIndex_b9628e75250e866c(arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    const ret = arg0.getUniformBlockIndex(arg1, v0);
    return ret;
};

export function __wbg_uniformBlockBinding_bcbb7fbc2fe88b8d(arg0, arg1, arg2, arg3) {
    arg0.uniformBlockBinding(arg1, arg2 >>> 0, arg3 >>> 0);
};

export function __wbg_getSyncParameter_0c83093c52867612(arg0, arg1, arg2) {
    const ret = arg0.getSyncParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbg_clientWaitSync_b3f79a980d4d9498(arg0, arg1, arg2, arg3) {
    const ret = arg0.clientWaitSync(arg1, arg2 >>> 0, arg3 >>> 0);
    return ret;
};

export function __wbg_beginQuery_ad59d7ffda61cf9e(arg0, arg1, arg2) {
    arg0.beginQuery(arg1 >>> 0, arg2);
};

export function __wbg_endQuery_feb28d278e32cfae(arg0, arg1) {
    arg0.endQuery(arg1 >>> 0);
};

export function __wbg_readBuffer_4c16fe804e5fd30c(arg0, arg1) {
    arg0.readBuffer(arg1 >>> 0);
};

export function __wbg_clearBufferuiv_d0ebea28b39eb980(arg0, arg1, arg2, arg3, arg4) {
    arg0.clearBufferuiv(arg1 >>> 0, arg2, getArrayU32FromWasm0(arg3, arg4));
};

export function __wbg_clearBufferiv_07046f3c028ef141(arg0, arg1, arg2, arg3, arg4) {
    arg0.clearBufferiv(arg1 >>> 0, arg2, getArrayI32FromWasm0(arg3, arg4));
};

export function __wbg_depthRange_c4d7339e2f6b2e4a(arg0, arg1, arg2) {
    arg0.depthRange(arg1, arg2);
};

export function __wbg_depthRange_8309e031492fd023(arg0, arg1, arg2) {
    arg0.depthRange(arg1, arg2);
};

export function __wbg_scissor_7206bcd2a5540aa3(arg0, arg1, arg2, arg3, arg4) {
    arg0.scissor(arg1, arg2, arg3, arg4);
};

export function __wbg_scissor_27cb154cc9864444(arg0, arg1, arg2, arg3, arg4) {
    arg0.scissor(arg1, arg2, arg3, arg4);
};

export function __wbg_stencilFuncSeparate_89563ca030dab790(arg0, arg1, arg2, arg3, arg4) {
    arg0.stencilFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3, arg4 >>> 0);
};

export function __wbg_stencilFuncSeparate_e6b4c784aa470ba1(arg0, arg1, arg2, arg3, arg4) {
    arg0.stencilFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3, arg4 >>> 0);
};

export function __wbg_stencilMaskSeparate_1303b1855315b85a(arg0, arg1, arg2) {
    arg0.stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_stencilMaskSeparate_6a90a6801f96c33e(arg0, arg1, arg2) {
    arg0.stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_stencilOpSeparate_fef362ec0f1539d1(arg0, arg1, arg2, arg3, arg4) {
    arg0.stencilOpSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
};

export function __wbg_stencilOpSeparate_f98bb31212170061(arg0, arg1, arg2, arg3, arg4) {
    arg0.stencilOpSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
};

export function __wbg_depthFunc_b78eec6735fd7a0a(arg0, arg1) {
    arg0.depthFunc(arg1 >>> 0);
};

export function __wbg_depthFunc_e49f522acf6c6c2d(arg0, arg1) {
    arg0.depthFunc(arg1 >>> 0);
};

export function __wbg_enableVertexAttribArray_c971ef03599058ec(arg0, arg1) {
    arg0.enableVertexAttribArray(arg1 >>> 0);
};

export function __wbg_enableVertexAttribArray_c2bfb733e87824c8(arg0, arg1) {
    arg0.enableVertexAttribArray(arg1 >>> 0);
};

export function __wbg_disableVertexAttribArray_fb822948cb54eec9(arg0, arg1) {
    arg0.disableVertexAttribArray(arg1 >>> 0);
};

export function __wbg_disableVertexAttribArray_557393d91e187e24(arg0, arg1) {
    arg0.disableVertexAttribArray(arg1 >>> 0);
};

export function __wbg_frontFace_e13136966c974dd8(arg0, arg1) {
    arg0.frontFace(arg1 >>> 0);
};

export function __wbg_frontFace_271693c85383f2e8(arg0, arg1) {
    arg0.frontFace(arg1 >>> 0);
};

export function __wbg_blendColor_71d54d4dad7a369a(arg0, arg1, arg2, arg3, arg4) {
    arg0.blendColor(arg1, arg2, arg3, arg4);
};

export function __wbg_blendColor_4416443539cdef95(arg0, arg1, arg2, arg3, arg4) {
    arg0.blendColor(arg1, arg2, arg3, arg4);
};

export function __wbg_bindBufferRange_bfdd227c2d5515af(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.bindBufferRange(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
};

export function __wbg_getQueryParameter_112c9a3c8a8dd0da(arg0, arg1, arg2) {
    const ret = arg0.getQueryParameter(arg1, arg2 >>> 0);
    return ret;
};

export function __wbg_stencilMask_76ea69a0c4738423(arg0, arg1) {
    arg0.stencilMask(arg1 >>> 0);
};

export function __wbg_stencilMask_4093c371489c5e3e(arg0, arg1) {
    arg0.stencilMask(arg1 >>> 0);
};

export function __wbg_vertexAttribDivisorANGLE_51dd5c906f4912a2(arg0, arg1, arg2) {
    arg0.vertexAttribDivisorANGLE(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_vertexAttribDivisor_aad38a21841ace46(arg0, arg1, arg2) {
    arg0.vertexAttribDivisor(arg1 >>> 0, arg2 >>> 0);
};

export function __wbg_drawElementsInstancedANGLE_fdf5cd2eb03dd141(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.drawElementsInstancedANGLE(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export function __wbg_drawElementsInstanced_19c02c2c6c7ebdd5(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.drawElementsInstanced(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export function __wbg_drawArraysInstancedANGLE_4ba856b2c59d84b8(arg0, arg1, arg2, arg3, arg4) {
    arg0.drawArraysInstancedANGLE(arg1 >>> 0, arg2, arg3, arg4);
};

export function __wbg_drawArraysInstanced_b0963fae97f2f14e(arg0, arg1, arg2, arg3, arg4) {
    arg0.drawArraysInstanced(arg1 >>> 0, arg2, arg3, arg4);
};

export function __wbg_framebufferRenderbuffer_27bc520ea685b484(arg0, arg1, arg2, arg3, arg4) {
    arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
};

export function __wbg_framebufferRenderbuffer_564b54a213de82b7(arg0, arg1, arg2, arg3, arg4) {
    arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
};

export function __wbg_framebufferTextureMultiviewOVR_b4f234dba08738d7(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.framebufferTextureMultiviewOVR(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5, arg6);
};

export function __wbg_readPixels_8260b74d4439418e() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
    arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
}, arguments) };

export function __wbg_readPixels_c1a5f8a1344005bd() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
    arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
}, arguments) };

export function __wbg_readPixels_32bab95664f5bcdf() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
    arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
}, arguments) };

export function __wbg_uniform4f_93ef17b7172e8ad2(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.uniform4f(arg1, arg2, arg3, arg4, arg5);
};

export function __wbg_uniform4f_5b57101145ac6da8(arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.uniform4f(arg1, arg2, arg3, arg4, arg5);
};

export function __wbindgen_closure_wrapper1604(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 325, __wbg_adapter_38);
    return ret;
};

export function __wbindgen_closure_wrapper2139(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 325, __wbg_adapter_41);
    return ret;
};

export function __wbindgen_closure_wrapper2182(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 325, __wbg_adapter_41);
    return ret;
};

export function __wbindgen_closure_wrapper33577(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_46);
    return ret;
};

export function __wbindgen_closure_wrapper33582(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_49);
    return ret;
};

export function __wbindgen_closure_wrapper33589(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_52);
    return ret;
};

export function __wbindgen_closure_wrapper33594(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_55);
    return ret;
};

export function __wbindgen_closure_wrapper33601(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_58);
    return ret;
};

export function __wbindgen_closure_wrapper33607(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_61);
    return ret;
};

export function __wbindgen_closure_wrapper33613(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_64);
    return ret;
};

export function __wbindgen_closure_wrapper33623(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_67);
    return ret;
};

export function __wbindgen_closure_wrapper33629(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_70);
    return ret;
};

export function __wbindgen_closure_wrapper33636(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_73);
    return ret;
};

export function __wbindgen_closure_wrapper33643(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_76);
    return ret;
};

export function __wbindgen_closure_wrapper33647(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_79);
    return ret;
};

export function __wbindgen_closure_wrapper33657(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_82);
    return ret;
};

export function __wbindgen_closure_wrapper33664(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 325, __wbg_adapter_85);
    return ret;
};

export function __wbindgen_closure_wrapper33673(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_88);
    return ret;
};

export function __wbindgen_closure_wrapper33678(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_91);
    return ret;
};

export function __wbindgen_closure_wrapper33683(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_94);
    return ret;
};

export function __wbindgen_closure_wrapper33695(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_97);
    return ret;
};

export function __wbindgen_closure_wrapper33705(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 325, __wbg_adapter_100);
    return ret;
};

export function __wbindgen_closure_wrapper33981(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 325, __wbg_adapter_103);
    return ret;
};

export function __wbindgen_init_externref_table() {
    const table = wasm.__wbindgen_export_2;
    const offset = table.grow(4);
    table.set(0, undefined);
    table.set(offset + 0, undefined);
    table.set(offset + 1, null);
    table.set(offset + 2, true);
    table.set(offset + 3, false);
    ;
};

